import consumer from './consumer';

// Função para simular globEager usando glob
function simulateGlobEager() {
  const modules = import.meta.glob('./**/*_channel.js');
  const eagerModules = {};

  return Promise.all(
    Object.entries(modules).map(([path, module]) => {
      return module().then(channel => {
        eagerModules[path] = channel;
      });
    })
  ).then(() => eagerModules);
}

// Carregue todos os canais dentro deste diretório e todos os subdiretórios.
// Os arquivos de canal devem ser nomeados *_channel.js.
simulateGlobEager().then(channels => {
  // Adicione canais ao consumer
  for (const path in channels) {
    consumer.addChannel(channels[path]);
  }
});